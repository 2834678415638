import * as _wrappy2 from "wrappy";

var _wrappy = "default" in _wrappy2 ? _wrappy2.default : _wrappy2;

import * as _asap2 from "asap";

var _asap = "default" in _asap2 ? _asap2.default : _asap2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var wrappy = _wrappy;
exports = wrappy(dezalgo);
var asap = _asap;

function dezalgo(cb) {
  var sync = true;
  asap(function () {
    sync = false;
  });
  return function zalgoSafe() {
    var args = arguments;
    var me = this || _global;
    if (sync) asap(function () {
      cb.apply(me, args);
    });else cb.apply(me, args);
  };
}

export default exports;